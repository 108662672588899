<template>
  <div class="mainRoot" :style="cssProps">
    <v-app-bar flat class="mainListHeader">
      <v-icon color="accent" size="45">$vuetify.icons.logo</v-icon>
    </v-app-bar>

    <div class="mainItemList" v-on:scroll="onHeaderScroll" ref="mainItemList">
      <ItemListVideo
        :videoItems="filteredVideoItems"
        v-on:itemClicked="itemClicked($event)"
        v-on:playItem="playItem($event)"
        v-on:playStarted="onPlayStarted($event)"
        class="pt-0 ma-0"
      />
    </div>

    <div
      class="videoItemList"
      v-if="$root.mediaPlayerItem != null && $root.mediaPlayer.isVideo"
    >
      <!-- Video player, current item info (including share) and a list of videos -->
      <PlayControllerVideo
        :playerId="videoPlayerId"
        style="flex-grow: 0; flex-shrink: 0"
        :item="$root.mediaPlayerItem"
        v-on:close="onClose()"
        v-on:openFullscreen="itemClicked($event)"
      />
    </div>
  </div>
</template>

<script>
  import Home from "./Home"
  import ItemListVideo from "../components/ItemListVideo"
  import Vue from "vue"
  import Vue2TouchEvents from "vue2-touch-events"
  import {mapState} from "vuex"

  import VueResizeSensor from "@seregpie/vue-resize-sensor"
  Vue.component(VueResizeSensor.name, VueResizeSensor)
  Vue.use(Vue2TouchEvents)

  export default {
    name: "Video",
    extends: Home,
    components: {
      ItemListVideo,
    },

    computed: {
      ...mapState({
        currentFeedCategoriesWithItems: (state) =>
          state.currentFeedCategoriesWithItems,
      }),
      filteredVideoItems() {
        if (!this.currentFeedCategoriesWithItems) {
          return []
        }
        const handleVideoTranslation = this.$t("video")

        const videoCategory = this.currentFeedCategoriesWithItems.find(
          (category) => category.category === handleVideoTranslation
        )

        return videoCategory ? videoCategory.items : []
      },
    },
    mounted() {
      this.updateFilteredItems()
      console.log("this.filteredItems", this.filteredItems)
    },

    data() {
      return {
        headerTitle: "Video",
        videoPlayerId: "videoPlaceholderController" + this.$route.name,
      }
    },
    methods: {
      updateMarquee() {
        this.itemTitle = "New Title"
      },
    },
  }
</script>
