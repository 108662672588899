<!-- tweaked from vue-read-more-smooth https://github.com/dicren/vue-read-more-smooth-->
<template>
  <div
    class="text-overflow"
    :class="{expanded: expanded, 'no-overflow': inMaxRange}"
  >
    <div ref="to" class="text-overflow-content">
      <slot> </slot>
    </div>

    <div @click.stop="toggle" class="button-read-more">
      <slot name="more" v-bind:open="expanded">
        <div
          v-if="readmore"
          class="read-more-button"
          :class="{'show-less': !noLess}"
        >
          <span>{{ expanded ? textLess : text }}</span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "vue-read-more-smooth",
    props: {
      lines: {
        type: Number,
        default: 3,
      },
      maxLines: {
        type: Number,
        default: null,
      },
      text: {
        type: String,
        default: "Read more",
      },
      textLess: {
        type: String,
        default: "Read less",
      },
      noLess: {
        type: Boolean,
        default: false,
      },
      open: {
        type: Boolean,
        default: null,
      },
    },
    data() {
      return {
        readmore: false,
        expanded: false,
        inMaxRange: false,
        localMaxLines: 1,
      }
    },
    watch: {
      open(v) {
        if (v !== this.expanded) {
          this.toggle(!v)
        }
      },
      items: {
        handler() {
          this.$forceUpdate()
          console.log("isupdating")
        },
        deep: true,
      },
    },
    created() {
      if (this.lines && !this.maxLines) {
        this.localMaxLines = this.lines + 1
      } else {
        this.localMaxLines = this.maxLines - 1
      }

      if (this.open === true) {
        this.expanded = true
      }
    },
    mounted() {
      const lh = this.getLineHeight(this.$refs.to)

      if (this.lines) {
        this.$refs.to.style.setProperty("--nlines", this.lines)
      }

      if (this.open === true) {
        this.$refs.to.style.setProperty("max-height", "100%")
      }

      setTimeout(() => {
        this.readmore = this.$refs.to.offsetHeight <= this.$refs.to.scrollHeight

        if (this.$refs.to.scrollHeight <= this.localMaxLines * lh) {
          this.inMaxRange = true
          this.readmore = false
        }

        this.$refs.to.style.setProperty("--lineHeight", lh + "px")
        if (this.$refs.ht) {
          this.$refs.ht.style.setProperty("--lineHeight", lh + "px")
        }

        if (this.open === true) {
          this.$refs.to.style.setProperty(
            "max-height",
            this.$refs.to.scrollHeight + "px"
          )
        }
      })
    },
    methods: {
      toggle(val) {
        const valExp = typeof val === "boolean" ? val : this.expanded

        if (valExp) {
          if (!this.noLess) {
            this.$refs.to.style.removeProperty("max-height")
            this.expanded = false
            this.$emit("update:open", false)
          }
        } else {
          this.expanded = true
          this.$emit("update:open", true)
          this.$refs.to.style.setProperty(
            "max-height",
            this.$refs.to.scrollHeight + "px"
          )
        }
      },
      getLineHeight(element) {
        let temp = document.createElement(element.children[0].nodeName)
        const cpStyle = getComputedStyle(element.children[0])
        temp.setAttribute(
          "style",
          `position: absolute; left: -999em; margin: 0; padding: 0; font-family: ${cpStyle.fontFamily}; font-size: ${cpStyle.fontSize}; line-height: ${cpStyle.lineHeight}`
        )
        temp.innerHTML = "test"
        temp = document.body.appendChild(temp)
        const lineHeight = getComputedStyle(temp).lineHeight
        temp.parentNode.removeChild(temp)
        const ret = lineHeight.replace("px", "")
        return ret
      },
    },
  }
</script>

<style scoped>
  .text-overflow-content {
    --nlines: 3;
    --lineHeight: 1.5;
    max-height: calc(var(--nlines) * var(--lineHeight));
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .text-overflow {
    position: relative;
  }

  .no-overflow {
    .text-overflow-content {
      max-height: 100%;
      overflow: visible;
    }

    .hide-text,
    .button-read-more {
      display: none;
    }
  }

  .hide-text {
    --nlines: 6;
    --lineHeight: 1.5;
    width: 100%;
    height: calc(var(--nlines) * var(--lineHeight));
    position: absolute;
    transform: translateY(calc(var(--nlines) * -1 * var(--lineHeight)));
  }

  .hide-text,
  .read-more-button {
    transition: opacity 0.2s ease, margin 0.3s ease;
    opacity: 1;
  }

  .expanded {
    .hide-text,
    .read-more-button:not(.show-less) {
      opacity: 0;
      margin-top: 0;
      margin-bottom: 0;
      pointer-events: none;
    }
  }
</style>
