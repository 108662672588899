<template>
  <v-container fluid grid-list-lg mt-4 pb-1 pt-4 pl-0 pr-0 ma-0>
    <v-layout column ref="card" xs12>
      <v-flex @click="itemClicked()" grow xs12 mt-0 pt-0>
        <v-img
          aspect-ratio="1.77"
          max-height="270px"
          :src="imageSrcOrFeedImage"
          @error="$logger.logFetchError(imageSrcOrFeedImage)"
          ma-0
          pa-0
        />
      </v-flex>
      <v-flex @click="itemClicked()" grow xs12 ma-0 pa-0>
        <v-layout
          row
          xs12
          ma-0
          pa-0
          mx-2
          class="flex-nowrap justify-space-between"
        >
          <v-flex grow xs10 ma-0 pa-0>
            <div
              :class="{
                smallHeadline: true,
                smallHeadline2lines: true,
                selected: isSelected,
              }"
            >
              {{ item.title }}
            </div>
          </v-flex>
          <v-flex shrink ma-0 pa-0>
            <ItemFavoriteButton
              class="align-start"
              :item="item"
              mediaType="video"
            />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex @click="itemClicked()" xs12 ma-0 px-2 class="listDescription">
        <VueReadMoreSmooth :lines="2" :max-lines="3" class="pb-3">
          <div
            :key="descriptionKey"
            style="font-weight: 200;font-size: calc(.8 * var(--v-theme-font-size));line-height: var( --v-theme-description-featured-line-height);word-break: break-word;"
          >
            {{ item.description }}
          </div>
          <template v-slot:more="value">
            <div
              class="pt-1 storyText noscale"
              style="color: var(--v-accent-lighten1); font-weight: 700; font-size: calc(.7 * var(--v-theme-font-size));  font-family:var(--v-theme-font);"
            >
              {{ value.open ? $t("show_less") : $t("show_more") }}
            </div>
          </template>
        </VueReadMoreSmooth>
      </v-flex>
      <v-flex
        xs12
        ma-0
        mx-2
        pa-0
        pb-4
        style="min-width: 70px"
        class="ma-0 pa-0"
      >
        <div
          style="flex: 0 0 auto;line-height:15px"
          ref="date"
          class="d-flex-inline align-stretch nobreak pa-0 ma-0"
        >
          <DateView class="date me-1" :date="item.pubDate" ago />
          <v-icon class="ma-0 pa-0 tiny" small>$vuetify.icons.typeVideo</v-icon>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import ItemBase from "./ItemBase"
  import db from "../database"
  import DateView from "./DateView"
  import ItemFavoriteButton from "./ItemFavoriteButton"
  import MediaCache from "../mediacache"
  import VueReadMoreSmooth from "./ReadMore.vue"

  export default {
    extends: ItemBase,
    components: {
      DateView,
      ItemFavoriteButton,
      VueReadMoreSmooth,
    },
    props: {
      isSelected: {
        type: Boolean,
        default: function() {
          return false
        },
      },
      items: {
        type: Object,
      },
    },
    watch: {
      item: function() {
        if (this.feedImageSrc != null) {
          MediaCache.releaseMedia(this.feedImageSrc)
        }
        this.feedImageSrc = null
        console.log("VideoItem - item changed!!!")
        this.updateItem()
      },
    },
    mounted: function() {
      this.updateItem()
    },
    data: () => ({
      feedImageSrc: null,
      descriptionKey: 0,
    }),
    computed: {
      imageSrcOrFeedImage: function() {
        return this.feedImageSrc == null ? this.imageSrc : this.feedImageSrc
      },
    },
    methods: {
      itemClicked() {
        this.$emit("itemClicked", {
          item: this.item,
          rect: this.$refs.card.getBoundingClientRect(),
        })
        this.$store.state.isAudioPlaying = false
        this.$store.commit("showingFullScreenVideo", true)
      },

      updateItem() {
        if (this.item.imageSrc != null) {
          MediaCache.getMedia(this.item.feedImageSrc, true, function(url) {
            self.feedImageSrc = url
          })
          return
        }
        if (!this.hasImage) {
          console.log("Video item - no image!!!")
          const self = this
          db.getFeed(this.item.feed).then((feed) => {
            if (feed != null) {
              MediaCache.getMedia(feed.imageUrl, true, function(url) {
                console.log("Using feed blob " + url)
                self.feedImageSrc = url
              })
            }
          })
        }
      },
    },
  }
</script>

<style scoped>
  .selected {
    color: #637568 !important;
    filter: brightness(70%);
  }

  .listDescription {
    word-wrap: break-word;
    font-size: calc(0.8 * var(--v-theme-font-size));
  }
</style>
