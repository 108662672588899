<template>
    <div class="ma-0 px-3 pa-0" ref="container">
        <v-list ref="list" class="itemList">
            <div v-for="(item, index) in videoItems" :key="item.guid">
                <v-lazy ma-0 pa-0 transition="fade-transition" min-height="70px">
                    <VideoItem
                        :ref="item.guid"
                        class="ma-0 pa-0"
                        :item="item"
                        :isSelected="
                            item.guid == $store.state.mediaPlayerItem.guid &&
                                $store.state.isAudioPlaying
                        "
                        v-on:itemClicked="playItem($event)"
                        v-on:playItem="playItem($event)"
                        v-on:openFullscreen="itemClicked($event)"
                    />
                </v-lazy>
                <!-- If not last item, add a divider between items -->
                <div ma-0 pa-0 v-if="index < videoItems.length - 1" class="listDivider " />
            </div>
        </v-list>
    </div>
</template>

<script>
    import ItemListBase from "./ItemListBase";
    import VideoItem from "./VideoItem";

    export default {
        extends: ItemListBase,
        components: {
            VideoItem,
        },
        props: {
            videoItems: Array,
        },
    };
</script>
